module Shared {
    export class CompanyDividendSplit {
        public contactNumber: number;
        public customerName: string;
        public percentageOfShareholding: number;
        public percentageOfDividends: number;
        public role: string;
        public noOfShares: number;
        public glAccountNo: number;
        public typeOfShare: string;
    }
}