/// <reference path="./../dividends/CompanyDividendPaymentSplit.ts" />
/// <reference path="./../dividends/CompanyDividendPaymentGrouped.ts" />

module Shared {
    export class TaxYearMonthSummary {
        constructor() { }
        public Title: string;
        public TotalDividendPayment: number;
        public StartDate: Date;
        public EndDate: Date;
        public Transactions: Array<Shared.CompanyDividendPaymentSplit>;
        public EditableTransactions: Array<Shared.CompanyDividendPaymentGrouped>;
    }
}