/// <reference path="../../../../app/_all.ts"/>
/// <reference path="./../../../shared/models/NavPersonalInformation.ts" />

module Shared {
    
    export class BrooksonUiAccountDetailsPersonalInformation implements ng.IComponentOptions {
        public controller: any;
        public templateUrl: any;
        public bindings: any;

        constructor() {
            this.bindings = {
                personalInformation: '='
            };
            this.controller = BrooksonUiAccountDetailsPersonalInformationController;
            this.templateUrl = 'src/app/shared/views/ui/brookson.ui.account-details.personal-information.html';
        }
    }
    
    class BrooksonUiAccountDetailsPersonalInformationController {
        personalInformation: Shared.NavPersonalInformation;
        nationalities: Array<NAVNationality>;
        showNationalitySpinner: boolean;

        static $inject = ['$scope', 'accountSrv', '$window'];

        constructor(private $scope: ng.IScope, private accountSrv: IAccountService, private $window: Window) { }

        $onInit = () => {
            this.showNationalitySpinner = true;

            this.accountSrv.getNationalities(true).then((data) => {
                this.showNationalitySpinner = false;
                this.nationalities = data;
                this.findNationality();
            });

            this.$scope.$watch('$ctrl.personalInformation.nationality', (newValue, oldValue) => {
                if (!oldValue) {
                    this.findNationality();
                }
            }, true);
        }

        findNationality = () => {
            if (this.personalInformation && this.personalInformation.nationality) {
                this.personalInformation.nationalityOption = _.find(this.nationalities, (nationality) => nationality.code === this.personalInformation.nationality);
            }
        }

        showTfa = (): boolean => {
            if (this.$window.appSettings.enable2FAForAllMembers === 'True') {
                return true;
            }
            return false;
        }
    }
}

angular
    .module('app.shared')
    .component('brooksonUiAccountDetailsPersonalInformation', new Shared.BrooksonUiAccountDetailsPersonalInformation());