/// <reference path="./../../shared/models/ShareholderSplit.ts" />
/// <reference path="./../../shared/models/IncomeProfit.ts" />
/// <reference path="./../../shared/models/CtData.ts" />
/// <reference path="./../../shared/models/dividends/CompanyDividendSplit.ts" />
/// <reference path="./../../shared/models/dividends/CompanyDividendPayments.ts" />
/// <reference path="./../../shared/models/dividends/CompanyDividendPaymentSplit.ts" />
/// <reference path="./../../shared/models/dividends/TaxYearSelection.ts" />
/// <reference path="./../../shared/models/dividends/TaxYearMonthSummary.ts" />
/// <reference path="./../../shared/models/dividends/ProposedDividend.ts" />

module Shared {
    export interface IMoneyManagerService {
        getTransactions(bankId?: string, silentMode?: boolean);
        getMoneyManagerStatements(yearStart: moment.Moment, yearEnd: moment.Moment, silentMode?: boolean);
        getMoneyManagerStatementsByOffset(offset:number, silentMode?: boolean);
        getMoneyManagerFinancialData(silentMode?: boolean);
        getMoneyAvailableStatement(silentMode?: boolean);
        getYearEndNotification(silentMode?: boolean);
        getPersonalTaxCalculations(mmShareholder: string, taxYear: string, silentMode?: boolean);
        getWeeklyAccountSummary(startDate: moment.Moment, endDate: moment.Moment, silentMode?: boolean);
        getTransactionStatements(startDate: moment.Moment, endDate: moment.Moment, silentMode?: boolean);
        getAccountScheduleLastUpdateDate(silentMode?: boolean): ng.IPromise<Date>;
        getMoneyManagerData(silentMode?: boolean): ng.IPromise<any>;
        weeklyAccountSummaryLogic();
        moneyAvailablePanelLogic();
        weeklyAccountSummary: any;
        multiWeeklyAccountSummary: Array<any>;
        selectedWeeklyAccountSummary: any;
        getNewBusinessStatus();
        isNewSetup: boolean;
        getShareholderSplit(silentMode?: boolean): ng.IPromise<any>;
        getIncomeProfitData(silentMode?: boolean): ng.IPromise<Array<IncomeProfit>>;
        getCtUpdateData(silentMode?: boolean): ng.IPromise<ICtData>;
        updateCtTime(newTime: string): ng.IPromise<ICtData>;
        getCompanyDividendSplit(): ng.IPromise<Array<CompanyDividendSplit>>;
        getCompanyDividendPayments(): ng.IPromise<Array<CompanyDividendPayments>>;
        saveProposedCompanyDividendPayments(selectedTaxYear: Shared.TaxYearSelection, monthlyTransactions: Array<Shared.TaxYearMonthSummary>): ng.IPromise<boolean>;
        getProposedDividendPayments(silentMode?: boolean): ng.IPromise<Array<Shared.ProposedDividend>>;
    }
}

(() => {
    "use strict";

    angular
        .module("app.shared")
        .service("moneyManagerSrv", moneyManagerSrv);

    moneyManagerSrv.$inject = ["$http", "$q", "shared.config", "brookson.utilities.date"];

    function moneyManagerSrv($http, $q, sharedConfig, dateUtils, ) {

        var moneyManagerSrvFactory: any = {};
        var serviceBase = sharedConfig.baseApiUrl;

        // Data
        var myMoneyAvailable: any = {};
        var weeklyAccountSummary: any = [];
        var selectedWeeklyAccountSummary: any = {};
        var startOfWeek = moment().startOf("week").toDate();
        var tmp;
        // var newBusinessStatus:boolean;
        var isNewSetup: boolean = false;

        // Requests
        function getTransactions(bankId, silentMode) {

            var url = serviceBase + "api/MoneyManager/GetTransactions";

            var requestData = {
                bankId: bankId !== undefined ? bankId : ""
            };

            return $http.get(url, {
                cache: true,
                params: requestData,
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function getMoneyManagerStatements(yearStart, yearEnd, silentMode) {
            var url = serviceBase + "api/MoneyManager/GetMoneyManagerStatements";

            var requestData = {
                yearStart: yearStart.toDate(),
                yearEnd: yearEnd.toDate()
            };

            return $http.get(url, {
                params: requestData,
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }
        
        function getMoneyManagerStatementsByOffset(offset, silentMode) {
            var url = serviceBase + "api/MoneyManager/GetMoneyManagerStatementsByOffset";

            var requestData = {
                offset: offset
            };

            return $http.get(url, {
                params: requestData,
                cache: "false",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function getMoneyManagerFinancialData(silentMode) {
            var url = serviceBase + "api/MoneyManager/GetMoneyManagerFinancialData";

            return $http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function getMoneyAvailableStatement(silentMode) {
            var url = serviceBase + "api/MoneyManager/GetMoneyAvailableStatement";

            return $http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function getYearEndNotification(silentMode) {
            var url = serviceBase + "api/MoneyManager/GetYearEndNotification";

            return $http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function getPersonalTaxCalculations(mmShareholder, taxYear, silentMode) {
            var url = serviceBase + "api/MoneyManager/GetPersonalTaxCalculations";

            var requestData = {
                request: {
                    shareholder: mmShareholder,
                    taxYear: taxYear
                }
            };

            return $http.post(url, {
                params: requestData,
                silentMode: silentMode ? silentMode : false
            }).then((response) => {

                return response.data;
            });
        }

        function getWeeklyAccountSummary(startDate, endDate, silentMode) {
            var url = serviceBase + "api/MoneyManager/GetWeeklyAccountSummary";

            var requestData = {
                startDate: startDate.toDate(),
                endDate: endDate.toDate()
            };

            return $http.get(url, {
                params: requestData,
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {

                return response.data;
            });
        }

        function getTransactionStatements(startDate, endDate, silentMode) {
            var url = serviceBase + "api/MoneyManager/GetTransactionStatements";

            var requestData = {
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
            };

            return $http.get(url, {
                params: requestData,
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function getAccountScheduleLastUpdateDate(silentMode) {
            return $http.get(serviceBase + "api/MoneyManager/GetAccountScheduleLastUpdateDate", {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            })
                .then((response) => {
                    return response.data;
                });
        }

        function getMoneyManagerData(silentMode) {
            return $http.get(serviceBase + "api/MoneyManager/GetMoneyManagerData", {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            })
                .then((response) => {
                    return response.data;
                });
        }

        function getNewBusinessStatus(silentMode: boolean) {

            return $http.get(serviceBase + "api/MoneyManager/GetNewBusinessStatus", {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            })
                .then((response) => {
                    return response.data;
                });
        }

        function getIncomeProfitData(silentMode: boolean) {
            return $http.get(serviceBase + "api/MoneyManager/GetIncomeProfitChart", {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            })
                .then((response) => {
                    return response.data;
                });
        }

        // Parse Data
        function weeklyAccountSummaryLogic() {

            var deferred = $q.defer();

            moneyManagerSrvFactory.getAccountScheduleLastUpdateDate().then((lastUpdateDateData) => {

                var startDate = moment().subtract(3, "month").startOf("day");
                var endDate = moment().startOf("day");

                var lastUpdateDate = dateUtils.convertToMoment(lastUpdateDateData);

                if (lastUpdateDate.isBefore(endDate)) {
                    endDate = lastUpdateDate;
                }

                moneyManagerSrvFactory.getWeeklyAccountSummary(startDate, endDate).then((weeklyAccountSummaryData) => {
                    var weeklyAccountSummary = weeklyAccountSummaryData;

                    weeklyAccountSummary.reverse();

                    for (var i = 0; i < weeklyAccountSummary.length; i++) {
                        weeklyAccountSummary[i].businessTotal = 0;
                        weeklyAccountSummary[i].personalTotal = 0;

                        for (var j = 0; j < weeklyAccountSummary[i].businessTransactions.length; j++) {
                            weeklyAccountSummary[i].businessTotal += weeklyAccountSummary[i].businessTransactions[j].amount;
                        }

                        for (j = 0; j < weeklyAccountSummary[i].personalTransactions.length; j++) {
                            weeklyAccountSummary[i].personalTotal += weeklyAccountSummary[i].personalTransactions[j].amount;
                        }
                    }

                    selectedWeeklyAccountSummary = weeklyAccountSummary[0];

                    moneyManagerSrvFactory.weeklyAccountSummary = weeklyAccountSummary;
                    moneyManagerSrvFactory.selectedWeeklyAccountSummary = selectedWeeklyAccountSummary;

                    deferred.resolve(weeklyAccountSummary);
                }, (er) => {
                    deferred.reject(er);
                });
            }, (er) => {
                deferred.reject(er);
            });
            return deferred.promise;
        }

        function moneyAvailablePanelLogic(silentMode?: boolean) {
            return this.getNewBusinessStatus(silentMode).then((result) => {

                    moneyManagerSrvFactory.isNewSetup = false;

                    moneyManagerSrvFactory.tmp = _.filter(moneyManagerSrvFactory.myMoneyAvailable.aftStatementChildRows, { rowCode: "AF1" });
                    if (moneyManagerSrvFactory.tmp.length && moneyManagerSrvFactory.tmp[0].yearToDateValue) {
                        moneyManagerSrvFactory.myMoneyAvailable.bankAccounts = moneyManagerSrvFactory.tmp[0].childMoneyManagerStatementRows;
                    } 
                    
                    moneyManagerSrvFactory.myMoneyAvailable.unpaidInvoices = moneyManagerSrvFactory.moneyManagerFinancialData.lessUnpaidInvoices;
                    moneyManagerSrvFactory.myMoneyAvailable.myMoneyAvailable = moneyManagerSrvFactory.moneyManagerFinancialData.totalMoneyAvailable;
                    
                    moneyManagerSrvFactory.myMoneyAvailable.myMoneyAvailableAdjusted =
                        moneyManagerSrvFactory.myMoneyAvailable.myMoneyAvailable +
                        moneyManagerSrvFactory.myMoneyAvailable.unpaidInvoices;
                });

        }

        function getShareholderSplit(silentMode?: boolean): ng.IPromise<Array<Shared.ShareholderSplit>> {
            var url = serviceBase + "api/MoneyManager/GetShareholderSplit";

            return $http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function getCtUpdateData(silentMode?: boolean): ng.IPromise<Shared.ICtData> {
            const url = serviceBase + "api/MoneyManager/GetCtUpdateData";

            return $http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function updateCtTime(newTime: string): ng.IPromise<Shared.ICtData> {
            return $http.post(serviceBase + 'api/MoneyManager/UpdateCtTime', JSON.stringify(newTime))
                .then((response) => response.data);
        }

        function getCompanyDividendSplit(silentMode?: boolean): ng.IPromise<Array<Shared.CompanyDividendSplit>> {
            var url = serviceBase + "api/MoneyManager/getCompanyDividendSplit";

            return $http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function getCompanyDividendPayments(silentMode?: boolean): ng.IPromise<Array<Shared.CompanyDividendPayments>> {
            var url = serviceBase + "api/MoneyManager/getCompanyDividendPayments";

            return $http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        function saveProposedCompanyDividendPayments(selectedTaxYear: Shared.TaxYearSelection, monthlyTransactions: Array<Shared.TaxYearMonthSummary>): ng.IPromise<boolean> {
            var requestData = {
                selectedTaxYear: selectedTaxYear,
                taxMonth: monthlyTransactions
            };

            return $http.post(serviceBase + 'api/MoneyManager/SaveProposedCompanyDividendPayments', requestData)
                .then((response) => response.data); 
        }

        function getProposedDividendPayments(silentMode?: boolean): ng.IPromise<Array<Shared.ProposedDividend>> {
            var url = serviceBase + "api/MoneyManager/GetProposedDividendShareholderPayments";

            return $http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        moneyManagerSrvFactory.getTransactions = getTransactions;
        moneyManagerSrvFactory.getMoneyManagerStatements = getMoneyManagerStatements;
        moneyManagerSrvFactory.getMoneyManagerStatementsByOffset = getMoneyManagerStatementsByOffset;
        moneyManagerSrvFactory.getMoneyAvailableStatement = getMoneyAvailableStatement;
        moneyManagerSrvFactory.getYearEndNotification = getYearEndNotification;
        moneyManagerSrvFactory.getPersonalTaxCalculations = getPersonalTaxCalculations;
        moneyManagerSrvFactory.getMoneyManagerFinancialData = getMoneyManagerFinancialData;
        moneyManagerSrvFactory.getWeeklyAccountSummary = getWeeklyAccountSummary;
        moneyManagerSrvFactory.getTransactionStatements = getTransactionStatements;
        moneyManagerSrvFactory.myMoneyAvailable = myMoneyAvailable;
        moneyManagerSrvFactory.weeklyAccountSummary = weeklyAccountSummary;
        moneyManagerSrvFactory.selectedWeeklyAccountSummary = selectedWeeklyAccountSummary;
        moneyManagerSrvFactory.startOfWeek = startOfWeek;
        moneyManagerSrvFactory.tmp = tmp;
        moneyManagerSrvFactory.weeklyAccountSummaryLogic = weeklyAccountSummaryLogic;
        moneyManagerSrvFactory.moneyAvailablePanelLogic = moneyAvailablePanelLogic;
        moneyManagerSrvFactory.getAccountScheduleLastUpdateDate = getAccountScheduleLastUpdateDate;
        moneyManagerSrvFactory.getMoneyManagerData = getMoneyManagerData;
        moneyManagerSrvFactory.getNewBusinessStatus = getNewBusinessStatus;
        moneyManagerSrvFactory.isNewSetup = isNewSetup;
        moneyManagerSrvFactory.getShareholderSplit = getShareholderSplit;
        moneyManagerSrvFactory.getIncomeProfitData = getIncomeProfitData;
        moneyManagerSrvFactory.getCtUpdateData = getCtUpdateData;
        moneyManagerSrvFactory.updateCtTime = updateCtTime;
        moneyManagerSrvFactory.getCompanyDividendSplit = getCompanyDividendSplit;
        moneyManagerSrvFactory.getCompanyDividendPayments = getCompanyDividendPayments;
        moneyManagerSrvFactory.saveProposedCompanyDividendPayments = saveProposedCompanyDividendPayments;
        moneyManagerSrvFactory.getProposedDividendPayments = getProposedDividendPayments;

        return moneyManagerSrvFactory;
    }
})();
